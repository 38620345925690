import { Input, InputWrapper, TriangleNav } from '@/components/common'
import InputLabel from '@/components/common/InputLabel'
import { css } from 'styled-system/css'
import React from 'react'
import { Control, useController, UseFormRegister } from 'react-hook-form'
import { styled } from 'styled-system/jsx'
import FormError from './FormError'
import { Button } from '@planda/design-system'
import DayOfTheWeek from './DayOfTheWeek'
import { DayRecurrence } from '@/lib/zod'

const Recurrence = ({
    control,
    errors,
    register,
    name,
    label = 'Schedule',
}: {
    control: Control<any>
    errors?: any
    register: UseFormRegister<any>
    label?: string
    name: string
}) => {
    const curErrors = errors?.[name]

    const { field: formatField } = useController<{ [name: string]: DayRecurrence | undefined }>({
        name,
        control,
    })
    const { value: formatCron, onChange } = formatField
    const formatIsCron = formatCron && 'daysOfWeek' in formatCron
    const toggleFormat = () => {
        onChange(formatCron && 'daysOfWeek' in formatCron ? { onceEveryXDays: 2 } : { daysOfWeek: [new Date().getDay()] })
    }

    return (
        <InputWrapper>
            <InputLabel>&#128337;&nbsp;&nbsp;{label}</InputLabel>
            <Flex>
                <TriangleNav direction="left" height={0.8} fn={toggleFormat} />
                {formatIsCron ? (
                    <DayOfTheWeek dayOfWeekAsNumber control={control} errors={errors} name={name + '.daysOfWeek'} />
                ) : (
                    <FlexV>
                        <Flex>
                            once every{' '}
                            <Input
                                {...register(name + '.onceEveryXDays', { valueAsNumber: true })}
                                className={css({ width: 'min-content', display: 'inline' })}
                                type="number"
                                min={0}
                                max={99}
                                id="counter"
                                size={'small'}
                                error={!!curErrors?.onceEveryXDays?.message}
                            />{' '}
                            days
                        </Flex>
                        <FormError name={name + '.onceEveryXDays'} errors={errors} />
                    </FlexV>
                )}

                <TriangleNav direction="right" height={0.8} fn={toggleFormat} />
            </Flex>
            <Button type={'button'} onClick={toggleFormat} className={css({ opacity: 0.5, fontSize: '0.5em' })} variant={'gray'}>
                Switch to {formatIsCron ? 'interval' : 'day of week'}
            </Button>
        </InputWrapper>
    )
}

export default Recurrence

const FlexV = styled('div', {
    base: {
        display: 'flex',
        gap: 10,
        flexDirection: 'column',
        alignItems: 'center',
    },
})
const Flex = styled('div', {
    base: {
        display: 'flex',
        gap: 12,
        alignItems: 'center',
    },
})
