'use client'
import React from 'react'
import { DOT_TO_COLOR, HabitDot, toNewHabitEntry } from './types'
import { css } from 'styled-system/css'
import { token } from 'styled-system/tokens'
import { usePutHabitEntryMutation } from '@/redux/features/api'
import { changeTime, mod } from '@planda/utils'
import { isToday } from 'date-fns'

const Dot = ({ dot, habitId, date }: { dot: HabitDot | undefined; isShiny?: boolean; habitId?: string; date: number }) => {
    const [put] = usePutHabitEntryMutation()
    const backgroundColor = dot ? DOT_TO_COLOR[dot] : token('colors.$loContrast')
    return (
        <button
            className={css({
                display: 'block',
                borderRadius: '$pill',
                height: 20,
                width: 20,
                border: '0.75px solid {colors.$hiContrast}',
                margin: 'auto',
                _hover: { cursor: habitId ? 'pointer' : undefined },
                _disabled: { cursor: 'not-allowed' },
            })}
            disabled={!habitId}
            style={{ backgroundColor }}
            onClick={() => {
                if (!habitId) return
                const nextDot = mod((dot || 0) + 1, 4)
                const newEntry = toNewHabitEntry(habitId, nextDot, isToday(date) ? Date.now() : changeTime(date, Date.now()))
                put(newEntry)
            }}
        />
    )
}

export default Dot
