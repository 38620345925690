'use client'
import { Popover } from '@/components/common'
import React, { memo, useState } from 'react'
import { ErrorText, formStyles } from '../forms/styles'
import { Button, Input } from '@planda/design-system'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { SingleHabit, SingleHabitSchema } from './types'
import { css } from 'styled-system/css'
import { usePutHabitMutation } from '@/redux/features/api'
import { nanoid } from 'nanoid'
import EmojiPicker from 'emoji-picker-react'
import { iconButton } from 'styled-system/recipes'
import Recurrence from '../forms/item/components/Recurrence'
import { UnimportantCheckbox } from '@/components/common/Checkbox'

const getDefaultHabit = (defaultValues?: Partial<SingleHabit>): SingleHabit => ({
    name: '',
    id: nanoid(6),
    target: '',
    level: 0,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    ...defaultValues,
})

const HabitFormPopover = ({ children, defaultValues }: { children: JSX.Element; defaultValues?: Partial<SingleHabit> }) => {
    const [put] = usePutHabitMutation()
    const [openEmojiPicker, setOpenEmojiPicker] = useState(true)
    const [open, setOpen] = useState(false)
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors, isSubmitting, isSubmitSuccessful },
    } = useForm({
        defaultValues: { ...getDefaultHabit(defaultValues) },
        resolver: zodResolver(SingleHabitSchema),
    })
    const [isDaily, setIsDaily] = useState(!defaultValues?.recurrence)
    const emoji = watch('symbol')
    const isEditingExisting = !!defaultValues?.id

    return (
        <Popover className={css({ maxWidth: '100vw' })} open={open} setOpen={setOpen} trigger={children}>
            <form
                onSubmit={handleSubmit((values) => {
                    put(values)
                    setOpen(false)
                })}
                className={css(formStyles.raw(), { background: 'inherit', maxWidth: '100%', fontSize: '$2' })}
            >
                <h2>{isEditingExisting ? 'Edit' : 'Create'} a habit</h2>
                <Input {...register('name')} placeholder="ex. Drink water" title="Habit name:" label={'Short name:'}>
                    {errors.name?.message && <ErrorText>{errors.name?.message}</ErrorText>}
                </Input>
                <Input {...register('target')} placeholder="ex. Drink 7L a day" title="Habit target:" label={'Target:'}>
                    {errors.target?.message && <ErrorText>{errors.target?.message}</ErrorText>}
                </Input>
                <Controller
                    name="symbol"
                    control={control}
                    render={({ field }) => (
                        <>
                            <EmojiPicker
                                className={css({
                                    '--epr-emoji-size': '20px',
                                    '& li': { margin: '0' },
                                    maxWidth: 500,
                                })}
                                reactions={['1f6b0', '1f4aa', '1f957', '1f6cc', '23f0', '1f4a7', '1f3b8', '1f3c0', '1f48a', '1faa5']}
                                style={{
                                    // @ts-ignore
                                    '--epr-emoji-size': '20px',
                                    '--epr-header-padding': '8px var(--epr-horizontal-padding)',
                                    '--epr-preview-height': '40px',
                                }}
                                open={openEmojiPicker}
                                reactionsDefaultOpen
                                onEmojiClick={(emoji) => {
                                    // console.log('emoji', emoji.unified)
                                    field.onChange(emoji.emoji)
                                    setOpenEmojiPicker(false)
                                }}
                                width={300}
                                height={300}
                            />
                            {!openEmojiPicker ? (
                                <button className={iconButton({ variant: 'ghost' })} type="button" onClick={() => setOpenEmojiPicker(true)}>
                                    {emoji}
                                </button>
                            ) : null}
                        </>
                    )}
                />
                <UnimportantCheckbox
                    checked={isDaily}
                    onCheckedChange={(checked) => {
                        setIsDaily(checked)
                        if (checked) {
                            setValue('recurrence', undefined)
                        } else {
                            setValue('recurrence', { onceEveryXDays: 2 })
                        }
                    }}
                    label="Complete every day"
                    info="Keep this checked if habit should be completed every day. If you have a specific schedule, uncheck this."
                />
                {isDaily ? null : <Recurrence control={control} errors={errors} register={register} name="recurrence" />}
                <Button width={'max-content'} disabled={isSubmitting || isSubmitSuccessful} type="submit">
                    {isEditingExisting ? 'Save' : 'Create'}
                </Button>
                <ul className={css({ maxWidth: 500, fontSize: 'small' })}>
                    Tips:
                    <li>Do you feel adversion to your target? If yes, lower the bar, make it easier.</li>
                    {/* <li>Remember that gradual change is lasting change. Start small.</li> */}
                    <li>The smaller the change, the more likely you are to succeed at making it.</li>
                    <li>We&apos;ll remind you to raise the bar after 20 sucesses in a row.</li>
                </ul>
                {/* <Input label='Name' placeholder="Habit Name" /> */}
            </form>
        </Popover>
    )
}

export default memo(HabitFormPopover)
