'use client'
import { daysInRange } from '@/utils/date'
import { endOfDay, format, isSameDay, isToday, startOfDay } from 'date-fns'
import React, { memo, useMemo } from 'react'
import Dot from './Dot'
import { useGetHabitsOrderQuery, useGetHabitsResQuery } from '@/redux/features/api'
import { MS_PER_DAY } from '@planda/utils'
import { css, cx } from 'styled-system/css'
import { HabitDot, HabitEntry, SingleHabit } from './types'
import PawPrints from '@/components/common/loaders/PawPrints'
import { tableSlots } from 'styled-system/recipes'
import { recurrenceOccursInRange, recurrenceOccursOnDate } from '@/lib/zod'
import HabitHeaders from './HabitHeaders'

// import { table as tableRecipe } from 'styled-system/recipes'

// 20 green dots in a row means you can up difficulty
// greed or red dot for whether you met target
// set bar as low as possible, so you don't feel adversion
// Remember that
// gradual change is lasting change
// A samari has no goal, only a path

const cell = css.raw({ paddingInline: '$2', border: '1px solid {colors.$gray4}' })
const HabitTracker = ({
    filterOutNotRelevant,
    days = 30,
    labelType,
}: {
    filterOutNotRelevant?: boolean
    days?: number
    labelType?: 'icons' | 'short'
}) => {
    const { table, th, tr, thead, tbody, td } = tableSlots({ alignTh: labelType === 'icons' ? 'center' : 'start' })
    const nowDay = new Date().getDate()
    const { relevantDays, start, end } = useMemo(() => {
        const start = startOfDay(Date.now()).getTime() - MS_PER_DAY * (days - 1),
            end = endOfDay(Date.now()).getTime()
        return { start, end, relevantDays: daysInRange(start, end).toReversed() }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nowDay, days])

    const { data, isLoading } = useGetHabitsResQuery({ start, end })
    const { data: habitsOrderData } = useGetHabitsOrderQuery()
    const { entries: entriesMap = {}, earliestEntryDate } = data || {}

    const habits = useMemo(() => {
        const order = habitsOrderData?.order
        const habits =
            data?.habits.filter(({ recurrence }) => {
                return recurrence && filterOutNotRelevant ? recurrenceOccursInRange(recurrence, start, end) : true
            }) || []
        if (!order) return habits
        return habits.sort((a, b) => {
            return order.indexOf(a.id) - order.indexOf(b.id)
        })
    }, [data, habitsOrderData, start, end, filterOutNotRelevant])

    if (isLoading) return <PawPrints />
    return (
        <table className={cx(table, css({ tableLayout: 'auto', borderCollapse: 'collapse' }))}>
            <thead className={thead}>
                <tr className={tr}>
                    <HabitHeaders habits={habits} labelType={labelType} className={th} />
                </tr>
            </thead>
            <tbody className={tbody}>
                {/* // Today must be at the top, and must be editable */}
                {relevantDays
                    .filter((day) => (earliestEntryDate ? day >= startOfDay(earliestEntryDate).getTime() : isToday(day)))
                    .map((day) => {
                        return <HabitEntryCells habits={habits} td={td} tr={tr} key={day} day={day} entriesMap={data?.entries} />
                    })}
            </tbody>
        </table>
    )
}

export default memo(HabitTracker)

const HabitEntryCells = ({
    day,
    habits,
    tr,
    td,
    entriesMap = {},
}: {
    day: number
    habits: SingleHabit[]
    tr: string
    td: string
    entriesMap: Record<string, HabitEntry[]> | undefined
}) => {
    const entries = habits.map(({ id, name, target, createdAt, recurrence }) => {
        return {
            id,
            name,
            target,
            entry: entriesMap[id]?.find((entry) => isSameDay(entry.date, day)),
            habitCreatedAt: createdAt,
            recurrence,
        }
    })

    const getDot = (entry: HabitEntry | undefined, habitCreatedAt: number, habit: Partial<SingleHabit> | undefined) => {
        const isInvalidOnDay = habit?.recurrence ? !recurrenceOccursOnDate(habit.recurrence, day, habitCreatedAt) : false
        return entry ? entry.dot : day < startOfDay(habitCreatedAt).getTime() || isInvalidOnDay ? HabitDot.GRAY : HabitDot.UNSET
    }
    const allGood = entries.every(({ entry, habitCreatedAt, ...rest }) => {
        const dot = getDot(entry, habitCreatedAt, rest)
        return dot === HabitDot.GREEN || dot === HabitDot.GRAY
    })
    const allFinished = entries.every(({ entry, habitCreatedAt, ...rest }) => getDot(entry, habitCreatedAt, rest) !== HabitDot.UNSET)

    return (
        <tr className={tr} key={day}>
            <td className={cx(td, css(cell))}>{format(day, 'MMM d')}</td>
            {entries.map(({ entry, id, habitCreatedAt, ...rest }) => {
                const dot = getDot(entry, habitCreatedAt, rest)
                return (
                    <td className={cx(td, css(cell))} key={entry?.id || id + day}>
                        <Dot date={day} habitId={id} dot={dot} />
                    </td>
                )
            })}
            <td className={cx(td, css(cell))}>
                <Dot date={day} isShiny dot={allGood ? HabitDot.GREEN : allFinished ? HabitDot.RED : HabitDot.UNSET} />
            </td>
        </tr>
    )
}
