'use client'
import React from 'react'
import HabitTracker from '@/features/habit-tracker/HabitTracker'
import { css } from 'styled-system/css'
import { flex } from 'styled-system/recipes'
import { ActivityGraph } from '@/components/graphs/ActivityGraph'
import { useAppSelector } from '@/redux/hooks'

const Bottom = () => {
    const habitTrackerEnabled = useAppSelector((state) => state.featureAccess.featureAccessMap['habit-tracker'])
    const activityGraphsEnabled = useAppSelector((state) => state.featureAccess.featureAccessMap['habit-tracker'])

    return (
        <div className={flex({ justify: 'center', align: 'center', direction: 'column' })}>
            {habitTrackerEnabled ? (
                <HabitTracker filterOutNotRelevant days={1} labelType="icons" />
            ) : activityGraphsEnabled ? (
                <div className={flex({ justify: 'center' })}>
                    <div
                        className={css({
                            display: 'none',
                            '@media only screen and (min-width: 800px)': {
                                display: 'flex',
                            },
                        })}
                    >
                        <ActivityGraph type="completed" width={500} title="Tasks Completed" />
                    </div>
                    <div
                        className={css({
                            display: 'none',
                            '@media only screen and (min-width: 1345px)': {
                                display: 'flex',
                            },
                        })}
                    >
                        <ActivityGraph type="time" width={500} title="Planned Hours" />
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default Bottom
