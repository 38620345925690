import { ErrorMessage } from '@hookform/error-message'
import React from 'react'
import { FieldErrorsImpl } from 'react-hook-form'
import { ErrorText } from '../../styles'

const FormError = ({errors, name}: {name: string, errors: Partial<FieldErrorsImpl<any>>}) => {
    return (
        <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <ErrorText>{message}</ErrorText>}
        />
    )
}

export default FormError